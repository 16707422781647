export const MenuFooterTwo =[
    {
    title: "Power Only",
    url: "/Poweronly",
    cName:"footer-linkstwo",
    icon:""
},
{
    title: "Dry Van",
    url: "/Dryvan",
    cName:"footer-linkstwo",
    icon:""
},
{
title: "FlatBed",
url: "/Flatbed",
cName:"footer-linkstwo",
icon:""
},
    {
    title: "Box Truck",
    url: "/Boxtruck",
    cName:"footer-linkstwo",
    icon:""
},
    {
    title: "Reefer",
    url: "/Reefer",
    cName:"footer-linkstwo",
    icon:""
},
    {
    title: "Hot Short",
    url: "/Hotshort",
    cName:"footer-linkstwo",
    icon:""
},

]